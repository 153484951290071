    <template>
        <b-row class="btb_license_input_label">
            <b-overlay :show="isLoading">
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
                        <!-- general information section -->
                        <b-col sm="12">
                            <div class="group-form-card">
                                <b-card>
                                    <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.general_information')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                        <b-row>
                                            <!-- search by license no when create renew -->
                                            <b-col sm="6">
                                                <ValidationProvider name="License No" vid="registration_no" :rules="{required: false}">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="registration_no">
                                                        <template v-slot:label>
                                                            {{ $t('externalUser.license_no') }}
                                                        </template>
                                                        <b-form-input
                                                            type="text"
                                                            v-model="application.application.registration_no"
                                                            :readonly="$store.state.Auth.activeRoleId !== 1"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="6"></b-col>
                                            <!-- applicant_name_en  -->
                                            <b-col sm="6" v-if="application.service_id === 30 || application.service_id === 31 || application.service_id === 32 || application.service_id === 33 || application.service_id === 34 || userType === 1">
                                            <ValidationProvider name="Applicant Name (En)" vid="applicant_name_en" rules="required">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="applicant_name_en">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.owner_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input v-if="application.service_id === 30"
                                                        :placeholder="$t('teaGardenPanel.broker_name_placeholder_condition')"
                                                        type="text"
                                                        v-model="application.applicant_name_en"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <b-form-input v-else
                                                        type="text"
                                                        v-model="application.applicant_name_en"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <!-- applicant_name_bn -->
                                            <b-col sm="6" v-if="application.service_id === 30 || application.service_id === 31 || application.service_id === 32 || application.service_id === 33 || application.service_id === 34 || userType === 1">
                                            <ValidationProvider name="Applicant Name (Bn)" vid="applicant_name_bn" rules="required">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="applicant_name_bn">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.owner_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input v-if="application.service_id === 30"
                                                        :placeholder="$t('teaGardenPanel.broker_name_placeholder_condition')"

                                                        type="text"
                                                        v-model="application.applicant_name_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <b-form-input v-else

                                                        type="text"
                                                        v-model="application.applicant_name_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <!-- company_name_en  -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Company Name (En)" vid="company_name_en" rules="required">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="company_name_en">
                                                        <template v-slot:label>
                                                            {{ $t('teaGardenPanel.applicant_company') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            type="text"
                                                            v-model="application.company_name_en"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- company_name_bn -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Company Name (Bn)" vid="company_name_bn" rules="required">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="company_name_bn">
                                                        <template v-slot:label>
                                                            {{ $t('teaGardenPanel.applicant_company') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            type="text"
                                                            v-model="application.company_name_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                             <!-- mobile -->
                                            <b-col sm="6">
                                            <ValidationProvider name="Mobile" vid="mobile" :rules="{required: true, min: 11, max: 11}">
                                                <b-form-group
                                                slot-scope="{ valid, errors }"
                                                    label-for="mobile">
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.mobile') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        @keypress="isNumber"
                                                        v-model="application.mobile"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <!-- email -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Email" vid="email" :rules="{required: true, email: true}">
                                                <b-form-group
                                                slot-scope="{ valid, errors }"
                                                    label-for="email">
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.email') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        v-model="application.email"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- nid -->
                                            <b-col sm="6" v-if="application.service_id === 31 || application.service_id === 32 || application.service_id === 33 || application.service_id === 30">
                                                <ValidationProvider name="NID" vid="nid" :rules="{required: true, min: 10, max: 17}">
                                                <b-form-group
                                                slot-scope="{ valid, errors }"
                                                    label-for="nid">
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.nid') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        type="number"
                                                        @keypress="isNumber"
                                                        v-model="application.nid"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>
                                </b-card>
                            </div>
                        </b-col>
                        <!-- applicant address section -->
                        <b-col sm="12" v-if="application.service_id === 31 || application.service_id === 32 || application.service_id === 33">
                            <div class="group-form-card">
                                <b-card>
                                    <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.applicant_address')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                        <b-row>
                                            <!-- area section start here -->
                                            <!-- area type -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Area Type" vid="area_type_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="area_type_id">
                                                        <template v-slot:label>
                                                            {{ $t('teaGardenConfig.area_type') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="application.area_type_id"
                                                            id="area_type_id"
                                                            :options="getAreaTypeList"
                                                            @change="GetAreaTypeData(application.area_type_id)"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- division -->
                                            <b-col sm="6" v-show="ItemShow">
                                                <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="division_id">
                                                        <template v-slot:label>
                                                            {{ $t('teaGardenConfig.division') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="application.division_id"
                                                            id="division_id"
                                                            :options="divisionList"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- district -->
                                            <b-col sm="6" v-show="ItemShow">
                                                <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="district_id">
                                                        <template v-slot:label>
                                                            {{ $t('teaGardenConfig.district') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="application.district_id"
                                                            id="district_id"
                                                            :options="districtList"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- upazila -->
                                            <b-col sm="6" v-show="PauroshobaItemShow || UnionItemShow">
                                                <ValidationProvider name="Upazila" vid="upazila_id">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="upazila_id">
                                                        <template v-slot:label>
                                                            {{ $t('teaGardenConfig.upazila') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="application.upazila_id"
                                                            id="upazila_id"
                                                            :options="upazilaList"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- union -->
                                            <b-col sm="6" v-show="UnionItemShow">
                                                <ValidationProvider name="Union" vid="union_id">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="union_id">
                                                        <template v-slot:label>
                                                            {{ $t('teaGardenConfig.union') }}
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="application.union_id"
                                                            id="union_id"
                                                            :options="unionList"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- city_corporation_id -->
                                            <b-col sm="6" v-show="CityCorpItemShow">
                                                <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="city_corporation_id">
                                                        <template v-slot:label>
                                                            {{ $t('teaGardenConfig.city_corporation') }}
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="application.city_corporation_id"
                                                            id="city_corporation_id"
                                                            :options="cityCorporationList"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- pauroshoba_id -->
                                            <b-col sm="6" v-show="PauroshobaItemShow">
                                                <ValidationProvider name="Municipality" vid="pauroshoba_id">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="pauroshoba_id">
                                                        <template v-slot:label>
                                                            {{ $t('teaGardenConfig.municipality_name') }}
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="application.pauroshoba_id"
                                                            id="pauroshoba_id"
                                                            :options="municipalityList"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="6">
                                            <ValidationProvider name="Address (En)" vid="address_en" rules="required">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="address_en">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.address') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="application.address_en"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <b-col sm="6">
                                            <ValidationProvider name="Address (Bn)" vid="address_bn" rules="required">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="address_bn">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.address') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="application.address_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <!-- post code -->
                                            <b-col sm="6">
                                            <ValidationProvider name="Post Code" vid="post_code" :rules="{required: false}">
                                                <b-form-group
                                                slot-scope="{ valid, errors }"
                                                    label-for="post_code">
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.post_code') }}
                                                    </template>
                                                    <b-form-input
                                                        type="number"
                                                        @keypress="isNumber"
                                                        v-model="application.post_code"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <!-- area section end -->
                                        </b-row>
                                    </b-form-group>
                                </b-card>
                            </div>
                        </b-col>
                        <!-- company address section -->
                        <b-col sm="12">
                            <div class="group-form-card">
                                <b-card>
                                    <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.business_address')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                        <b-row>
                                        <b-col sm="12" v-if="application.service_id === 31 || application.service_id === 32 || application.service_id === 33">
                                            <ValidationProvider name="Address Status" vid="is_same" v-slot="{ errors }">
                                                <b-form-group
                                                    class="row mb-0"
                                                    label-for="is_same"
                                                >
                                                <b-form-checkbox
                                                    v-model="isSameAsApplicant"
                                                    value="1"
                                                    @change="isSameAsApplicantFunc($event)"
                                                    unchecked-value="0"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <span class="text-black">{{ $t('teaGardenPanel.same_as_applicant_address') }}</span>
                                                </b-form-checkbox>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                            <!-- area section start here -->
                                            <!-- area type -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Area Type" vid="c_area_type_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="c_area_type_id">
                                                        <template v-slot:label>
                                                            {{ $t('teaGardenConfig.area_type') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="application.c_area_type_id"
                                                            id="c_area_type_id"
                                                            :options="getAreaTypeList"
                                                            @change="comGetAreaTypeData(application.c_area_type_id)"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- division -->
                                            <b-col sm="6" v-show="comItemShow">
                                                <ValidationProvider name="Division" vid="c_division_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="c_division_id">
                                                        <template v-slot:label>
                                                            {{ $t('teaGardenConfig.division') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="application.c_division_id"
                                                            id="c_division_id"
                                                            :options="divisionList"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- district -->
                                            <b-col sm="6" v-show="comItemShow">
                                                <ValidationProvider name="District" vid="c_district_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="c_district_id">
                                                        <template v-slot:label>
                                                            {{ $t('teaGardenConfig.district') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="application.c_district_id"
                                                            id="c_district_id"
                                                            :options="comDistrictList"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- upazila -->
                                            <b-col sm="6" v-show="comPauroshobaItemShow || comUnionItemShow">
                                                <ValidationProvider name="Upazila" vid="c_upazila_id">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="c_upazila_id">
                                                        <template v-slot:label>
                                                            {{ $t('teaGardenConfig.upazila') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="application.c_upazila_id"
                                                            id="c_upazila_id"
                                                            :options="comUpazilaList"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- union -->
                                            <b-col sm="6" v-show="comUnionItemShow">
                                                <ValidationProvider name="Union" vid="c_union_id">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="c_union_id">
                                                        <template v-slot:label>
                                                            {{ $t('teaGardenConfig.union') }}
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="application.c_union_id"
                                                            id="c_union_id"
                                                            :options="comUnionList"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- city corporation -->
                                            <b-col sm="6" v-show="comCityCorpItemShow">
                                                <ValidationProvider name="City Corporation" vid="c_city_corporation_id">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="c_city_corporation_id">
                                                        <template v-slot:label>
                                                            {{ $t('teaGardenConfig.city_corporation') }}
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="application.c_city_corporation_id"
                                                            id="c_city_corporation_id"
                                                            :options="comCityCorporationList"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- pauroshova -->
                                            <b-col sm="6" v-show="comPauroshobaItemShow">
                                                <ValidationProvider name="Municipality" vid="c_pauroshoba_id">
                                                    <b-form-group
                                                        slot-scope="{ valid, errors }"
                                                        label-for="c_pauroshoba_id">
                                                        <template v-slot:label>
                                                            {{ $t('teaGardenConfig.municipality_name') }}
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="application.c_pauroshoba_id"
                                                            id="c_pauroshoba_id"
                                                            :options="comMunicipalityList"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- address  -->
                                            <b-col sm="6">
                                            <ValidationProvider name="Address (En)" vid="address_en" rules="required">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="address_en">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.address') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="application.c_address_en"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <b-col sm="6">
                                            <ValidationProvider name="Address (Bn)" vid="address_bn" rules="required">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="address_bn">
                                                    <template v-slot:label>
                                                        {{ $t('teaGardenPanel.address') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="application.c_address_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <!-- post code -->
                                            <b-col sm="6">
                                            <ValidationProvider name="Post Code" vid="post_code" :rules="{required: false}">
                                                <b-form-group
                                                slot-scope="{ valid, errors }"
                                                    label-for="post_code">
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.post_code') }}
                                                    </template>
                                                    <b-form-input
                                                        type="number"
                                                        @keypress="isNumber"
                                                        v-model="application.c_post_code"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <!-- area section end -->
                                        </b-row>
                                    </b-form-group>
                                </b-card>
                            </div>
                        </b-col>
                        <b-row class="text-right mb-3">
                        <b-col>
                            <b-button type="button" @click="saveUpdate()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.update') }}</b-button>
                            <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('certificate-change')">{{ $t('globalTrans.cancel') }}</b-button>
                        </b-col>
                        </b-row>
                    </b-form>
                </ValidationObserver>
          </b-overlay>
      </b-row>
    </template>
    <script>
    import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
    import { brokersCertificateUpdateApi, biddersCertificateUpdateApi, retailersCertificateUpdateApi, blendersCertificateUpdateApi, wearhouseCertificateUpdateApi } from '../../api/routes'
    import { helpers } from '@/utils/helper-functions'
    // import { VueEditor } from 'vue2-editor'
    import { mapGetters } from 'vuex'
    export default {
        props: ['id', 'service_id'],
        components: {
        },
        data () {
          return {
            teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
            CityCorpItemShow: false,
            UnionItemShow: false,
            PauroshobaItemShow: false,
            comCityCorpItemShow: false,
            comPauroshobaItemShow: false,
            comUnionItemShow: false,
            warCityCorpItemShow: false,
            warUnionItemShow: false,
            warPauroshobaItemShow: false,
            userType: this.$store.state.Auth.authUser.user_type,
            ItemShow: false,
            comItemShow: false,
            warItemShow: false,
            isSameAsApplicant: 0,
            isSameAsBusiness: 0,
            districtList: [],
            upazilaList: [],
            unionList: [],
            cityCorporationList: [],
            municipalityList: [],
            comDistrictList: [],
            comUpazilaList: [],
            comUnionList: [],
            comCityCorporationList: [],
            comMunicipalityList: [],
            warDistrictList: [],
            warUpazilaList: [],
            warUnionList: [],
            warCityCorporationList: [],
            warMunicipalityList: [],
            valid: null,
            editIndex: '',
            application: {
                garden_id: 0,
                parent_id: null,
                service_id: 33,
                area_type_id: 0,
                division_id: 0,
                district_id: 0,
                city_corporation_id: 0,
                pauroshoba_id: 0,
                upazila_id: 0,
                union_id: 0,
                c_area_type_id: 0,
                c_division_id: 0,
                c_district_id: 0,
                c_city_corporation_id: 0,
                c_pauroshoba_id: 0,
                c_upazila_id: 0,
                c_union_id: 0,
                w_area_type_id: 0,
                w_division_id: 0,
                w_district_id: 0,
                w_city_corporation_id: 0,
                w_pauroshoba_id: 0,
                w_upazila_id: 0,
                w_union_id: 0,
                business_type_id: 0,
                trade_license_fiscal_year_id: 0
            },
            search: {
                registration_no: ''
            },
            isLoading: false,
            isRenewTime: true,
            renewDataNotFound: false,
            license_expired: false,
            is_renew_data_found: false
          }
        },
        computed: {
            ...mapGetters({
                authUser: 'Auth/authUser'
            }),
            currentLocale () {
                return this.$i18n.locale
            },
            yesNoList () {
                return [
                    { text: this.currentLocale === 'en' ? 'Yes' : 'হ্যাঁ', value: 1, text_en: 'Yes', text_bn: 'হ্যাঁ' },
                    { text: this.currentLocale === 'en' ? 'No' : 'না', value: 2, text_en: 'No', text_bn: 'না' }
                ]
            },
            businessTypeList () {
              const data = [
                { value: 1, text: this.$i18n.locale === 'bn' ? 'ব্যাক্তি মালিকানাধীন' : 'Individual' },
                { value: 2, text: this.$i18n.locale === 'bn' ? 'লিমিটেড কোম্পানি' : 'Limited Company' },
                { value: 3, text: this.$i18n.locale === 'bn' ? 'অংশীদারি কারবার' : 'Partnership Business' },
                { value: 4, text: this.$i18n.locale === 'bn' ? 'সরকারি / আধা সরকারি / স্বায়ত্তশাসিত প্রতিষ্ঠান' : 'Govt / Semi Govt / Autonomous Institutions' }
              ]
              if (this.application.service_id === 30) {
                return data.filter(item => item.value === 2)
              }
              return data
            },
            wearhouseBusinessTypeList () {
              return [
                { value: 1, text: this.$i18n.locale === 'bn' ? 'ব্যাক্তি মালিকানাধীন' : 'Individual' },
                { value: 2, text: this.$i18n.locale === 'bn' ? 'লিমিটেড কোম্পানি' : 'Limited Company' },
                { value: 3, text: this.$i18n.locale === 'bn' ? 'অংশীদারি কারবার' : 'Partnership Business' }
              ]
            },
            applicationType () {
              return [
                { value: 1, text: this.$i18n.locale === 'bn' ? 'নতুন নিবন্ধন' : 'New Registration' },
                { value: 2, text: this.$i18n.locale === 'bn' ? 'নবায়ন' : 'Renew' }
              ]
            },
            wearhouseLandCheck () {
              return [
                { value: 1, text: this.$i18n.locale === 'bn' ? 'মালিকানাধীন' : 'Owned' },
                { value: 2, text: this.$i18n.locale === 'bn' ? 'চুক্তিবদ্ধ' : 'Contract' }
              ]
            },
            divisionList () {
                return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
            },
            getAreaTypeList: function () {
                return this.$store.state.commonObj.areaTypeList.map(el => {
                    return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
                })
            },
            loading () {
                return this.$store.state.commonObj.loading
            }
        },
        created () {
            if (this.id) {
                this.getAppDetail()
                // company area type
            }
        },
        methods: {
            async getAppDetail () {
                this.isLoading = true
            let serviceName = ''
            if (this.service_id === 30) {
                serviceName = 'broker-show'
            } else if (this.service_id === 31) {
                serviceName = 'blender-show'
            } else if (this.service_id === 32) {
                serviceName = 'bidder-show'
            } else if (this.service_id === 33) {
                serviceName = 'retailer-show'
            } else if (this.service_id === 34) {
                serviceName = 'wearhouse-show'
            }
            const result = await RestApi.getData(teaGardenServiceBaseUrl, '/btb/license-application/' + serviceName + '/' + this.id)
                if (result.success) {
                    this.application = Object.assign({}, this.application, result.data)
                    this.application.service_id = result.data.application.service_id
                    this.callAddress(this.application)
                    this.addressShowHide(this.application)
                }
                this.isLoading = false
            },
            async saveUpdate () {
                var check = await this.$refs.form.validate()
                if (check) {
                    this.isLoading = true
                    let result = ''
                    this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

                    let apiName = ''
                    if (this.service_id === 30) {
                        apiName = brokersCertificateUpdateApi
                    } else if (this.service_id === 31) {
                        apiName = blendersCertificateUpdateApi
                    } else if (this.service_id === 32) {
                        apiName = biddersCertificateUpdateApi
                    } else if (this.service_id === 33) {
                        apiName = retailersCertificateUpdateApi
                    } else if (this.service_id === 34) {
                        apiName = wearhouseCertificateUpdateApi
                    }

                    if (this.id && apiName !== '') {
                        result = await RestApi.putData(teaGardenServiceBaseUrl, `${apiName}/${this.id}`, this.application)
                    }
                    if (result.success) {
                        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
                        this.$toast.success({
                        title: 'Success',
                        message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                        })
                        this.$bvModal.hide('certificate-change')
                    } else {
                        this.$refs.form.setErrors(result.errors)
                        this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#ee5253'
                        })
                    }
                    this.isLoading = false
                }
            },
            addressShowHide (application) {
                  // company area
                  if (application.c_area_type_id === 1) {
                    this.comItemShow = true
                    this.comCityCorpItemShow = true
                    this.comPauroshobaItemShow = false
                    this.comUnionItemShow = false
                } else if (application.c_area_type_id === 2) {
                    this.comItemShow = true
                    this.comCityCorpItemShow = false
                    this.comPauroshobaItemShow = true
                    this.comUnionItemShow = false
                } else if (application.c_area_type_id === 3) {
                    this.comItemShow = true
                    this.comCityCorpItemShow = false
                    this.comPauroshobaItemShow = false
                    this.comUnionItemShow = true
                }
                // warehouse area type
                if (application.w_area_type_id === 1) {
                    this.warItemShow = true
                    this.warCityCorpItemShow = true
                    this.warPauroshobaItemShow = false
                    this.warUnionItemShow = false
                } else if (application.w_area_type_id === 2) {
                    this.warItemShow = true
                    this.warCityCorpItemShow = false
                    this.warPauroshobaItemShow = true
                    this.warUnionItemShow = false
                } else if (application.w_area_type_id === 3) {
                    this.warItemShow = true
                    this.warCityCorpItemShow = false
                    this.warPauroshobaItemShow = false
                    this.warUnionItemShow = true
                }
                // applicant area type
                if (application.area_type_id === 1) {
                    this.ItemShow = true
                    this.CityCorpItemShow = true
                    this.PauroshobaItemShow = false
                    this.UnionItemShow = false
                } else if (application.area_type_id === 2) {
                    this.ItemShow = true
                    this.CityCorpItemShow = false
                    this.PauroshobaItemShow = true
                    this.UnionItemShow = false
                } else if (application.area_type_id === 3) {
                    this.ItemShow = true
                    this.CityCorpItemShow = false
                    this.PauroshobaItemShow = false
                    this.UnionItemShow = true
                }
            },
            isSameAsApplicantFunc (ev) {
                this.callAddress(this.application)
                if (this.isSameAsApplicant === '1') {
                    // company address
                    this.$set(this.application, 'c_area_type_id', this.application.area_type_id)
                    this.$set(this.application, 'c_division_id', this.application.division_id)
                    this.$set(this.application, 'c_district_id', this.application.district_id)
                    this.$set(this.application, 'c_city_corporation_id', this.application.city_corporation_id)
                    this.$set(this.application, 'c_pauroshoba_id', this.application.pauroshoba_id)
                    this.$set(this.application, 'c_upazila_id', this.application.upazila_id)
                    this.$set(this.application, 'c_union_id', this.application.union_id)
                    this.$set(this.application, 'c_address_en', this.application.address_en)
                    this.$set(this.application, 'c_address_bn', this.application.address_bn)
                    this.$set(this.application, 'c_post_code', this.application.post_code)
                    // wareshouse address
                    this.$set(this.application, 'w_area_type_id', this.application.area_type_id)
                    this.$set(this.application, 'w_division_id', this.application.division_id)
                    this.$set(this.application, 'w_district_id', this.application.district_id)
                    this.$set(this.application, 'w_city_corporation_id', this.application.city_corporation_id)
                    this.$set(this.application, 'w_pauroshoba_id', this.application.pauroshoba_id)
                    this.$set(this.application, 'w_upazila_id', this.application.upazila_id)
                    this.$set(this.application, 'w_union_id', this.application.union_id)
                    this.$set(this.application, 'w_address_en', this.application.address_en)
                    this.$set(this.application, 'w_address_bn', this.application.address_bn)
                    this.$set(this.application, 'w_post_code', this.application.post_code)
                    this.addressShowHide(this.application)
                } else {
                    // company address
                    this.$set(this.application, 'c_area_type_id', 0)
                    this.$set(this.application, 'c_division_id', 0)
                    this.$set(this.application, 'c_district_id', 0)
                    this.$set(this.application, 'c_city_corporation_id', 0)
                    this.$set(this.application, 'c_pauroshoba_id', 0)
                    this.$set(this.application, 'c_upazila_id', 0)
                    this.$set(this.application, 'c_union_id', 0)
                    this.$set(this.application, 'c_address_en', '')
                    this.$set(this.application, 'c_address_bn', '')
                    this.$set(this.application, 'c_post_code', '')
                    // wareshouse address
                    this.$set(this.application, 'w_area_type_id', 0)
                    this.$set(this.application, 'w_division_id', 0)
                    this.$set(this.application, 'w_district_id', 0)
                    this.$set(this.application, 'w_city_corporation_id', 0)
                    this.$set(this.application, 'w_pauroshoba_id', 0)
                    this.$set(this.application, 'w_upazila_id', 0)
                    this.$set(this.application, 'w_union_id', 0)
                    this.$set(this.application, 'w_address_en', '')
                    this.$set(this.application, 'w_address_bn', '')
                    this.$set(this.application, 'w_post_code', '')
                }
            },
            isSameAsBusinessFunc (ev) {
                this.callAddress(this.application)
                if (this.isSameAsBusiness === '1') {
                    // company address
                    this.$set(this.application, 'w_area_type_id', this.application.c_area_type_id)
                    this.$set(this.application, 'w_division_id', this.application.c_division_id)
                    this.$set(this.application, 'w_district_id', this.application.c_district_id)
                    this.$set(this.application, 'w_city_corporation_id', this.application.c_city_corporation_id)
                    this.$set(this.application, 'w_pauroshoba_id', this.application.c_pauroshoba_id)
                    this.$set(this.application, 'w_upazila_id', this.application.c_upazila_id)
                    this.$set(this.application, 'w_union_id', this.application.c_union_id)
                    this.$set(this.application, 'w_address_en', this.application.c_address_en)
                    this.$set(this.application, 'w_address_bn', this.application.c_address_bn)
                    this.$set(this.application, 'w_post_code', this.application.c_post_code)
                    this.addressShowHide(this.application)
                } else {
                    // wareshouse address
                    this.$set(this.application, 'w_area_type_id', 0)
                    this.$set(this.application, 'w_division_id', 0)
                    this.$set(this.application, 'w_district_id', 0)
                    this.$set(this.application, 'w_city_corporation_id', 0)
                    this.$set(this.application, 'w_pauroshoba_id', 0)
                    this.$set(this.application, 'w_upazila_id', 0)
                    this.$set(this.application, 'w_union_id', 0)
                    this.$set(this.application, 'w_address_en', '')
                    this.$set(this.application, 'w_address_bn', '')
                    this.$set(this.application, 'w_post_code', '')
                }
            },
            isNumber (evt) {
              helpers.isNumber(evt)
            },
            checkBase64 (string) {
                var result = ''
                result = string.match('data:')
                if (result) {
                    return true
                } else {
                    return false
                }
            },
            // file upload
            onChange (event, field) {
                const input = event.target
                if (input.files && input.files[0]) {
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        this.application[field] = e.target.result
                    }
                    reader.readAsDataURL(input.files[0])
                } else {
                    this.application[field] = ''
                }
            },
            GetAreaTypeData (typeId) {
                this.ItemShow = true
                if (typeId === 1) {
                    this.CityCorpItemShow = true
                    this.PauroshobaItemShow = false
                    this.UnionItemShow = false
                } else if (typeId === 2) {
                    this.CityCorpItemShow = false
                    this.PauroshobaItemShow = true
                    this.UnionItemShow = false
                } else if (typeId === 3) {
                    this.CityCorpItemShow = false
                    this.PauroshobaItemShow = false
                    this.UnionItemShow = true
                }
            },
            comGetAreaTypeData (typeId) {
                this.comItemShow = true
                if (typeId === 1) {
                    this.comCityCorpItemShow = true
                    this.comPauroshobaItemShow = false
                    this.comUnionItemShow = false
                } else if (typeId === 2) {
                    this.comCityCorpItemShow = false
                    this.comPauroshobaItemShow = true
                    this.comUnionItemShow = false
                } else if (typeId === 3) {
                    this.comCityCorpItemShow = false
                    this.comPauroshobaItemShow = false
                    this.comUnionItemShow = true
                }
            },
            warGetAreaTypeData (typeId) {
                this.warItemShow = true
                if (typeId === 1) {
                    this.warCityCorpItemShow = true
                    this.warPauroshobaItemShow = false
                    this.warUnionItemShow = false
                } else if (typeId === 2) {
                    this.warCityCorpItemShow = false
                    this.warPauroshobaItemShow = true
                    this.warUnionItemShow = false
                } else if (typeId === 3) {
                    this.warCityCorpItemShow = false
                    this.warPauroshobaItemShow = false
                    this.warUnionItemShow = true
                }
            },
            getDistrictList (id) {
                if (id) {
                    return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1 && item.division_id === id)
                } else {
                    return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1)
                }
            },
            getUpazilaList (id) {
                return this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1 && item.district_id === id)
            },
            getUnionList (upazilaId) {
                return this.$store.state.CommonService.commonObj.unionList.filter(item => item.status === 1 && item.upazila_id === upazilaId)
            },
            getCityCorporationList (districtId) {
                const objectData = this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1 && item.district_id === districtId)
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            },
            getPauroshobaList (upazillaId = null) {
            const objectData = this.$store.state.CommonService.commonObj.municipalityList.filter(item => item.upazila_id === upazillaId)
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            },
            callAddress (application) {
                    // company address
                    if (application.c_area_type_id) {
                        this.comDistrictList = this.getDistrictList(application.c_division_id)
                        this.comCityCorporationList = this.getCityCorporationList(application.c_district_id)
                        this.comUpazilaList = this.getUpazilaList(application.c_district_id)
                        if (application.c_upazila_id) {
                            this.comMunicipalityList = this.getPauroshobaList(application.c_upazila_id)
                            this.comUnionList = this.getUnionList(application.c_upazila_id)
                        }
                    }
                    // warhouse addresss
                    if (application.w_area_type_id) {
                        this.warDistrictList = this.getDistrictList(application.w_division_id)
                        this.warCityCorporationList = this.getCityCorporationList(application.w_district_id)
                        this.warUpazilaList = this.getUpazilaList(application.w_district_id)
                        if (application.w_upazila_id) {
                            this.warMunicipalityList = this.getPauroshobaList(application.w_upazila_id)
                            this.warUnionList = this.getUnionList(application.w_upazila_id)
                        }
                    }
                    // applicant address
                    if (application.area_type_id) {
                        this.districtList = this.getDistrictList(application.division_id)
                        this.cityCorporationList = this.getCityCorporationList(application.district_id)
                        this.upazilaList = this.getUpazilaList(application.district_id)
                        if (application.upazila_id) {
                            this.municipalityList = this.getPauroshobaList(application.upazila_id)
                            this.unionList = this.getUnionList(application.upazila_id)
                        }
                    }
            }
        },
        watch: {
            'application.division_id': function (newVal, oldVal) {
                this.districtList = this.getDistrictList(newVal)
            },
            'application.district_id': function (newVal, oldVal) {
                this.cityCorporationList = this.getCityCorporationList(newVal)
                this.upazilaList = this.getUpazilaList(newVal)
            },
            'application.upazila_id': function (newVal, oldVal) {
                this.municipalityList = this.getPauroshobaList(newVal)
                this.unionList = this.getUnionList(newVal)
            },
            'application.c_division_id': function (newVal, oldVal) {
               this.comDistrictList = this.getDistrictList(newVal)
            },
            'application.c_district_id': function (newVal, oldVal) {
                this.comCityCorporationList = this.getCityCorporationList(newVal)
                this.comUpazilaList = this.getUpazilaList(newVal)
            },
            'application.c_upazila_id': function (newVal, oldVal) {
                this.comMunicipalityList = this.getPauroshobaList(newVal)
                this.comUnionList = this.getUnionList(newVal)
            },
            'application.w_division_id': function (newVal, oldVal) {
                this.warDistrictList = this.getDistrictList(newVal)
            },
            'application.w_district_id': function (newVal, oldVal) {
                this.warCityCorporationList = this.getCityCorporationList(newVal)
                this.warUpazilaList = this.getUpazilaList(newVal)
            },
            'application.w_upazila_id': function (newVal, oldVal) {
                this.warMunicipalityList = this.getPauroshobaList(newVal)
                this.warUnionList = this.getUnionList(newVal)
            },
            'application.service_id': {
                handler: function (newVal, oldVal) {
                    if (newVal === 30) {
                        this.application.business_type_id = 2
                    }
                },
                immediate: true
            },
            currentLocale: function () {
                // call by division
                if (this.application.division_id) {
                    this.districtList = this.getDistrictList(this.application.division_id)
                }
                if (this.application.c_division_id) {
                    this.comDistrictList = this.getDistrictList(this.application.c_division_id)
                }
                if (this.application.w_division_id) {
                    this.warDistrictList = this.getDistrictList(this.application.w_division_id)
                }
                // call by district
                if (this.application.district_id) {
                    this.cityCorporationList = this.getCityCorporationList(this.application.district_id)
                    this.upazilaList = this.getUpazilaList(this.application.district_id)
                }
                if (this.application.c_district_id) {
                    this.comCityCorporationList = this.getCityCorporationList(this.application.c_district_id)
                    this.comUpazilaList = this.getUpazilaList(this.application.c_district_id)
                }
                if (this.application.w_district_id) {
                    this.warCityCorporationList = this.getCityCorporationList(this.application.w_district_id)
                    this.warUpazilaList = this.getUpazilaList(this.application.w_district_id)
                }
                // call by upazila
                if (this.application.upazila_id) {
                    this.municipalityList = this.getPauroshobaList(this.application.upazila_id)
                    this.unionList = this.getUnionList(this.application.upazila_id)
                }
                if (this.application.c_upazila_id) {
                    this.comMunicipalityList = this.getPauroshobaList(this.application.c_upazila_id)
                    this.comUnionList = this.getUnionList(this.application.c_upazila_id)
                }
                if (this.application.w_upazila_id) {
                    this.warMunicipalityList = this.getPauroshobaList(this.application.w_upazila_id)
                    this.warUnionList = this.getUnionList(this.application.w_upazila_id)
                }
            }
        }
    }
    </script>

    <style>
    .font-bn .btb_license_input_label .custom-file-label {
      font-size: 14px;
      color: #7c7c7c;
    }
    .validation_msg {
      font-size: 11px;
    }
    </style>
